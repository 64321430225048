import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrganisationDetailsInfoPanelComponent} from 'src/app/shared/module/organisation-detail/organisation-details-info-panel/organisation-details-info-panel.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
  BasicDialogV2Service,
  CoreModule,
  DialogModule,
  DialogV2Service,
  InlineMessageComponent,
  MessageBoxModule, PageTitleModule,
  PaginatedListModule,
  SaveButtonModule,
  TableModule
} from '@sesame/sesame-fe-library';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConfigurePlatformUserDialogComponent} from "src/app/shared/module/organisation-detail/configure-platform-user-dialog/configure-platform-user-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatStepperModule} from "@angular/material/stepper";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatChipsModule} from "@angular/material/chips";
import {DeactivateOrganisationDialogComponent} from "src/app/shared/module/organisation-detail/deactivate-organisation-dialog/deactivate-organisation-dialog.component";
import {SmartschoolIntegrationDialogComponent} from "src/app/shared/module/organisation-detail/smartschool-integration-dialog/smartschool-integration-dialog.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {IconModule} from "src/app/shared/module/icon/icon.module";
import {OrganisationIntegrationComponent} from './organisation-integration/organisation-integration.component';
import {SmartschoolIntegrationDetailsComponent} from './smartschool-integration-details/smartschool-integration-details.component';
import {WisaIntegrationDetailsComponent} from './wisa-integration-details/wisa-integration-details.component';
import {SharedPipesModule} from "src/app/shared/pipes/shared-pipes.module";
import {ChangeSchoolDataMasterSystemDialogComponent} from 'src/app/shared/module/organisation-detail/change-school-data-master-system-dialog/change-school-data-master-system-dialog.component';
import {CommonComponentsModule} from "src/app/shared/module/common-components/common-components.module";
import {ProductSearchModule} from "src/app/shared/module/product-search/product-search.module";
import {OrganisationPlatformUserPanelComponent} from './organisation-platform-user-panel/organisation-platform-user-panel.component';
import {SelectDataMasterSystemPanelComponent} from './select-data-master-system-panel/select-data-master-system-panel.component';
import {SelectLinkedOrganisationPanelComponent} from './select-linked-organisation-panel/select-linked-organisation-panel.component';
import {DeleteDataSchoolDataMasterSystemDialogComponent} from "src/app/shared/module/organisation-detail/delete-data-school-data-master-system-dialog/delete-data-school-data-master-system-dialog.component";

@NgModule({
  declarations: [
    OrganisationDetailsInfoPanelComponent,
    ConfigurePlatformUserDialogComponent,
    DeactivateOrganisationDialogComponent,
    SmartschoolIntegrationDialogComponent,
    OrganisationIntegrationComponent,
    SmartschoolIntegrationDetailsComponent,
    WisaIntegrationDetailsComponent,
    ChangeSchoolDataMasterSystemDialogComponent,
    OrganisationPlatformUserPanelComponent,
    SelectDataMasterSystemPanelComponent,
    SelectLinkedOrganisationPanelComponent,
    DeleteDataSchoolDataMasterSystemDialogComponent,
  ],
  exports: [
    OrganisationDetailsInfoPanelComponent
  ],
  imports: [
    CommonModule,
    PageTitleModule,
    MatIconModule,
    TranslateModule,
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    TableModule,
    FormsModule,
    MatDialogModule,
    MatStepperModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    PaginatedListModule,
    MatInputModule,
    MatChipsModule,
    DialogModule,
    MatDatepickerModule,
    CoreModule,
    IconModule,
    SharedPipesModule,
    CommonComponentsModule,
    ProductSearchModule,
    InlineMessageComponent,
    SaveButtonModule,
    MessageBoxModule,
  ],
  providers: [
    {provide: DialogV2Service, useClass: BasicDialogV2Service}
  ]
})
export class OrganisationDetailModule {
}
