<form class="d-flex flex-column gap-4" [formGroup]="isPlatformUserForm">
  <div class="d-flex flex-row gap-4 align-items-center">
    <label class="section-label ">{{ 'organisation.platformUserLabel' | translate }}</label>
    <mat-slide-toggle *requireAllPrivileges="[PRIVILEGE_TYPE.MANAGE_ORGANISATION]"
                      color="primary"
                      labelPosition="before"
                      formControlName="isPlatformUser">
      <label *ngIf="isPlatformUserForm.value.isPlatformUser">{{ 'organisation.platformUser.state.on'  | translate | uppercase }}</label>
      <label *ngIf="!isPlatformUserForm.value.isPlatformUser">{{ 'organisation.platformUser.state.off'  | translate | uppercase }}</label>
    </mat-slide-toggle>
  </div>

  <div class="d-flex flex-row gap-1 align-items-center">
    <label>{{ 'organisation.platformUser.schoolDataMasterSystem' | translate }}</label>
    <span *ngIf="organisation.dataMasterType">{{ organisation.dataMaster }} {{ organisation.dataMasterType ? '(' + organisation.dataMasterType + ')' : '' }}</span>
    <span *ngIf="!organisation.dataMaster">{{'organisation.platformUser.notSet' | translate}}</span>
    <ng-content *requireAllPrivileges="[PRIVILEGE_TYPE.MANAGE_ORGANISATION]"></ng-content>
  </div>
</form>